/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import Auth from '@aws-amplify/auth'
import { setUser } from './src/utils/auth'
import { Amplify } from 'aws-amplify'
import config from './src/aws-exports.js'

const locationKey = 'lastlocation';
const isPwaKey = 'ispwa';
const pwaLoadedKey = 'pwaloaded';

Amplify.configure(config)

const isPwa = () => {
  const pwaValue = window.sessionStorage.getItem(isPwaKey);
  return pwaValue && pwaValue === "true";
}

const isInitializedRedirectIfNeeded = () => {
  const pwaLoaded = window.sessionStorage.getItem(pwaLoadedKey);

  if(!pwaLoaded && pwaLoaded !== "true" ) {
    const lastLocation = window.localStorage.getItem(locationKey);
    window.sessionStorage.setItem('docreferrer', document.referrer);
    if(document.referrer.includes('android-app://') || window.matchMedia('(display-mode: minimal-ui)').matches) {
      window.sessionStorage.setItem(isPwaKey, "true");
    } 
  
    if(isPwa() && lastLocation && lastLocation.startsWith('/') && lastLocation.length > 1 && !window.location.pathname.endsWith(lastLocation) ) {
      window.location = lastLocation;
    }

    window.sessionStorage.setItem(pwaLoadedKey, "true")
  }

}

export const onInitialClientRender = () => {
  
}

export const onRouteUpdate = ({location}) => {

  isInitializedRedirectIfNeeded();
  if(isPwa()) {
    window.localStorage.setItem(locationKey, location.pathname)
  }

  Auth.currentAuthenticatedUser()
    .then(user => {
      const userInfo = {
        ...user.attributes,
        username: user.username
      }
      setUser(userInfo)
    })
    .catch(err => {
      window.localStorage.setItem('gatsbyUser', null)
    })
}
