/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:e3c86930-40fc-46cd-b573-bf809a0f1efc",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_Io0RMsXMR",
    "aws_user_pools_web_client_id": "417lt7c3tlo8bc1f39v0g8ldtl",
    "oauth": {}
};


export default awsmobile;
